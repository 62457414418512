// Variables
@import "_variables";
html, body{
  font-size: 100%!important;
  @media(min-width: 1650px){
    font-size: 125%!important;
  }
}
nav.navbar.bootsnav{
  ul.nav{
    li {
      @media(max-width: 767px){
        display: block!important;
      }
    }
  }
}
body{
    font-family: 'Rubik', sans-serif;
}
.title-image-back{
  .tag{
    h1{
      font-size: 3rem;
    }
  }
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}
p {
	font-size: 1.5rem;
	font-family: 'Rubik', Arial, sans-serif;
	font-weight: 400;
	font-style: normal;
    line-height: 2rem;
	}
h1 {
	font-size: 4.2rem;
	font-family: 'Rubik', Arial, sans-serif;
	font-weight: 700;
	font-style: normal;
	line-height: 120%;
	text-transform: none;
	}

h2 {
	font-size: 2.8rem;
	font-family: 'Rubik', Arial, sans-serif;
	font-weight: 700;
	font-style: normal;
	line-height: 120%;
	letter-spacing: 0.03em;
	text-transform: none;
  color: $primary_color;
	}

h3 {
	font-size: 2.4rem;
	font-family: 'Rubik', Arial, sans-serif;
	font-weight: 700;
	font-style: normal;
	line-height: 120%;
	letter-spacing: 0.03em;
	text-transform: none;
	-webkit-font-smoothing: antialiased;
  color: $primary_color;
	}

h4 {
	font-size: 2.2rem;
	font-family: 'Rubik', Arial, sans-serif;
	font-weight: 400;
	font-style: normal;
	line-height: 120%;
	text-transform: none;
	letter-spacing: 0.03em;
	color: #00C7B4;
	text-align: left;
	}

h5 {
	font-size: 2.1rem;
	font-family: 'Rubik', Arial, sans-serif;
	font-weight: 700;
	font-style: normal;
	line-height: 120%;
	color: #3E444E;
	text-align: left;
	}
.content-columns, .action-billboard, .half-width-content-image-bg{
  a, a:visited {
  	color: #00C7B4;
  	text-decoration: none;
  	-webkit-transition: all .2s ease-in-out;
  	-moz-transition: all .2s ease-in-out;
  	-o-transition: all .2s ease-in-out;
  	transition: all .2s ease-in-out;
	}

  a:hover, a:active {
  	color: #200061;
  	text-decoration: none;
    border-bottom: 1px #200061 solid;
  	-webkit-transition: all .1s ease-in-out;
  	-moz-transition: all .1s ease-in-out;
  	-o-transition: all .1s ease-in-out;
  	transition: all .1s ease-in-out;
	}
}
.btn{
  border-radius: 10px;
  border-width: 2px!important;
}

.action-billboard{
  .btn{
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
  }
}
nav.navbar.bootsnav .navbar-toggle{
  float: right;
  margin-right: 0;
}
.top-nav{
  background-color: $secondary_color;
  padding: 5px 0;
  color: #FFFFFF;
  font-size: .9rem;
  a{
    color: $primary_color;
    font-size: 1.1rem;
  }
}
.navbar-default {
  .navbar-nav{
      padding: 15px 0;
    li{
      a{
    color: $secondary_color;
    font-size: 1rem;
    font-weight: 400!important;
      }
    }
  }
}
.navbar-brand{
  height: auto;
  img{
    max-height: none;
  }
}

.content-columns.dark{
  h3{
    color: $primary_color!important;
  }
}
/* CTA Carousel */

.cta-slider{
  background-color: $primary_color;
  .cta{
    @media(min-width: 992px){
      flex: 1 1 100%;
    }
    .content-container{
      width: 70%;
      margin: auto;
      padding: 50px;
      @media(max-width: 1200px){
        width: 80%;
      }
      @media(max-width: 767px){
        width: 100%;
        padding: 25px;
      }
      h2{
        color: #FFFFFF;
        font-weight: 400;
        font-size: 2rem;
      }
    }
  }
  .slider{
      @media(min-width: 992px){
        flex: 1 1 100%;
      }
      .swiper-container{
        margin-right: -1px!important;

          height: 60vh;

        @media(max-width: 1500px){
          height: 65vh;
        }
        @media(max-width: 991px){
          height: 60vh;
        }
      }
      .caption{
        background: rgba(255,255,255,0.88);
        padding: 20px;
        position: absolute;
        bottom: 0;
        p{
          font-size: 1.4rem;
          @media(max-width: 767px){
            font-size: 1.2rem;
            line-height: 1.5rem;
          }
        }
      }
  }
  .row{
      @media(min-width: 992px){
        display: flex;
      }
    height: 100%;
  }
}
.shadow-box{
  background: #FFFFFF;
  box-shadow: 0 0 17px 0 rgba(0,0,0,0.11);
  text-align: center;
  h3{
    font-size: 1.8rem;
    color: $primary_color!important;
  }
  p{
    color: $body_text_color!important;
  }
}
.swiper-container{
  height: 100%;
}
.swiper-slide {
    background-size: cover;
    background-position: top center;
}

.base-footer {
  padding-bottom: 0;
  h3{
    color: #FFFFFF;
    font-size: 2.2rem;
    text-transform: none;
    &:after{
      border: none;
    }
  }
  a.social-link{
    font-size: 1.3rem;
    &:hover{
      text-decoration: none;
      color: $secondary_color;
    }
  }
  .gform_description{
    font-size: 1.5rem!important;
  }
  input[type="text"]{
    background: transparent!important;
    border-left: none;
    border-right: none;
    border-top: none;
    color: #FFFFFF!important;
    border-bottom: 4px #FFF solid!important;
    width: 80%!important;
  }
  ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color:#FFFFFF;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color:#FFFFFF;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color:#FFFFFF;
  }
  :-moz-placeholder { /* Firefox 18- */
    color:#FFFFFF;
  }
  .sub-footer{
    margin-top: 2rem;
    background-color: rgba(0,0,0,0.1);
    padding: 10px;
    text-align: center;
  }
}


/* SPECIAL GRAVITY FORMS STYLING */
.gf_full_width{
  width: 100%;
  input{
    width:99.5%!important;
  }
}
.gf_left_half, .gf_right_half{
  width: 49.5%!important;
}
.hidden-label{
  label{
    display: none!important;
  }
}

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $primary_color;
}
::-moz-placeholder { /* Firefox 19+ */
  color: $primary_color;
}
:-ms-input-placeholder { /* IE 10+ */
  color: $primary_color;
}
:-moz-placeholder { /* Firefox 18- */
  color: $primary_color;
}
.gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]){
  padding:12px 15px!important;
  border: none;
  color: $primary_color;
  font-size: 1rem!important;
}
.styled-form{
  label{
    font-size: 1.1rem!important;
  }
  .gfield.background{
    background-color: #ffffff;
    float: left;
    width: 100%;
    padding: 25px;
    height: auto;
    &:after{
      clear:both;
    }
    .ginput_container_checkbox{
      float: left;
      width: 100%;
    }
    label{
      font-weight: 400;
    }
  }
  input[type=radio],
  input[type='checkbox'], .gfield_checkbox br {
    display: none!important;
  }
  .gfield_checkbox li{
    float:left!important;
    max-width: 100%;
    width: 60%;
  }
  input[type=radio] + label {
    display: block;
  }
input[type='checkbox'] + label{
  font-size: 1rem!important;
  font-weight: 400!important;
  color: $primary_color;
}
  input[type='checkbox'] + label:before,
  input[type='radio'] + label:before {
    color: $primary_color;
    font-size: 1.6rem;
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 15px;
    width: 23px;
  }
  input[type=radio] + label:before {
    content: "\f10c"; /* Radio Unchecked */
    float: left;
  }
  input[type=radio]:checked + label:before {
    content: "\f05d"; /* Radio Checked */
    float: left;
  }
  input[type="checkbox"] + label:before {
    content: "\f096"; /* Checkbox Unchecked */
    float: left;
  }
  input[type="checkbox"]:checked + label:before {
    font-weight: 400!important;
    content: "\f046"; /* Checkbox Checked */
    float: left;
  }
  .radio label,
  .checkbox label {
    padding-left: 0;
    font-weight: 400;
    color: $primary_color!important;
  }
}


/* FULL SCREEN OVERLAY*/

/* Overlay style */
.overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
  z-index: 1100;
  padding-top: 50px;
  @media(max-width: 767px){
    padding-top: 25px;
  }
   background: rgba(0,198,179,0.82);
   .overlay-box{
     background-color: #ffffff;
     padding: 5px 20px 20px 20px;
     width: 70%;
     margin: auto;
     @media(max-width: 767px){
       width: 90%;
     }
     h2{
       text-transform: uppercase;
       font-weight: 400;
     }
     p{
       color: $secondary_color;
       font-size: 1.1rem;
       line-height: 1.4rem;
       span.primary{
         color: $primary_color;
       }
       span.gray{
         color: #5E5E5E;
       }
     }
   }
}

/* Overlay closing cross */
.overlay .overlay-close {
	width: 30px;
	height: 30px;
  float: right;
	right: 20px;
	top: 20px;
  font-size: 24px;
  font-weight: bold;
  background: none;
	overflow: hidden;
	border: none;
	color: $secondary_color;
	outline: none;
	z-index: 100;
}

/* Effects */
.overlay-scale {
	visibility: hidden;
	opacity: 0;
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
	-webkit-transition: -webkit-transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
	transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}

.overlay-scale.open {
	visibility: visible;
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: -webkit-transform 0.4s, opacity 0.4s;
	transition: transform 0.4s, opacity 0.4s;
}

@media screen and (max-height: 30.5em) {
	.overlay nav {
		height: 70%;
		font-size: 34px;
	}
	.overlay ul li {
		min-height: 34px;
	}
}
